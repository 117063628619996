<template>
  <div id="human-contract">
    <!-- 工商提成表 -->
    <div class="human-contract-screen">
      <div class="human-contract-screen-line">
        <div class="human-contract-screen-search">
          <el-input
            placeholder="客户名称"
            v-model="searchData.clientName"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button>
          </el-input>
        </div>
        <div class="human-contract-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="resetPage"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="human-contract-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="seleteInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-contract-screen-search">
          <el-date-picker
            v-model="searchData.dataDate"
            type="month"
            value-format="yyyy-MM"
            placeholder="选择月"
            :clearable="false"
            @change="resetPage"
          >
          </el-date-picker>
        </div>
        <div class="human-contract-screen-search">
          <el-select
            v-model="searchData.receiptsStatus"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option label="未收款" value="未收款"> </el-option>
            <el-option label="部分收款" value="部分收款"> </el-option>
            <el-option label="已收款" value="已收款"> </el-option>
          </el-select>
        </div>
        <div class="human-contract-screen-active"></div>
        <el-button
          style="margin-right: 10px"
          type="primary"
          size="small"
          @click="handleClick({}, 0)"
          :loading="buttonLoading"
          v-showFunction="{
            active: 'synchronization',
            array: allFunctionButtons,
          }"
          >合同提成同步</el-button
        >
      </div>
      <div class="human-contract-screen-line">
        <div class="human-contract-screen-search">
          <el-select
            v-model="searchData.chargeUpStatus"
            @change="resetPage"
            filterable
            clearable
            placeholder="请选择记账状态"
          >
            <el-option label="未完成" value="1"></el-option>
            <el-option label="已完成" value="2"></el-option>
          </el-select>
        </div>
        <div class="human-contract-screen-search">
          <el-select
            v-model="searchData.checkStatus"
            @change="resetPage"
            filterable
            clearable
            placeholder="请选择审核状态"
          >
            <el-option label="未审核" value="1"></el-option>
            <el-option label="未通过" value="2"></el-option>
            <el-option label="已通过" value="3"></el-option>
          </el-select>
        </div>
        <div class="human-contract-screen-search">
          <el-select
            v-model="searchData.clientType"
            @change="resetPage"
            filterable
            clearable
            placeholder="请选择公司类型"
          >
            <el-option label="一般纳税人" value="一般纳税人"></el-option>
            <el-option label="小规模纳税人" value="小规模纳税人"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="human-contract-table">
      <div class="human-contract-table-card">
        <div class="human-contract-table-card-tabs">
          <div
            :class="{
              'human-contract-table-card-tab': true,
              'is-active': tabindex == 1,
            }"
            @click="changeTab(1)"
          >
            详情
          </div>
          <div
            :class="{
              'human-contract-table-card-tab': true,
              'is-active': tabindex == 2,
            }"
            @click="changeTab(2)"
          >
            统计
          </div>
        </div>
        <div class="human-contract-table-card-content" v-show="tabindex == 1">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            :row-style="getTableChildrenStyle"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="index" width="60" fixed> </el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenuData"
              :key="index"
              :property="item.prop"
              :fixed="item.fixed"
              :label="item.title"
              :width="item.prop == 'clientName' ? 250 : item.width"
              :show-overflow-tooltip="true"
            >
              <template slot="header">
                <span>{{ item.title }}</span>
                <i
                  v-if="item.icon"
                  v-showFunction="{
                    active: 'edit',
                    array: allFunctionButtons,
                  }"
                  class="el-icon-edit-outline"
                  style="color: #5CB6FF; cursor: pointer; margin-left: 3px;"
                  @click="handleClick({}, 1, item.prop)"
                ></i>
              </template>
              <template slot-scope="scope">
                <div v-if="item.type == 'input'">
                  <el-input
                    v-model="scope.row[item.prop]"
                    :onkeyup="
                      (scope.row[item.prop] = scope.row[item.prop]
                        .toString()
                        .replace(/[^0-9.]/g, ''))
                    "
                    @change="changeItem($event, scope.row, item.prop)"
                  >
                  </el-input>
                  <span>%</span>
                </div>
                <span v-else>
                  {{ scope.row[item.prop] }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="human-contract-table-card-content" v-show="tabindex == 2">
          <el-table
            ref="singleTable2"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            :row-style="getTableChildrenStyle"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="index" width="60"> </el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenuData2"
              :key="index"
              :property="item.prop"
              :label="item.title"
              :width="item.width"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span
                  v-if="item.prop == 'completeUserName'"
                  @click.stop="handleClick(scope.row, 4)"
                  style="color: #40a3ff; cursor: pointer;"
                  >{{ scope.row[item.prop] }}</span
                >
                <div class="royaltyRate" v-else-if="item.prop == 'royaltyRate'">
                  <el-input
                    v-model="scope.row[item.prop]"
                    @change="
                      changeItem2(
                        scope.row[item.prop],
                        scope.row,
                        item.prop,
                        item.updateKey
                      )
                    "
                    :disabled="editFlag"
                  >
                  </el-input>
                  <span>%</span>
                </div>
                <span v-else>
                  {{ scope.row[item.prop] }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="human-contract-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-sizes="[10, 30, 50, 100, 500, 1000]"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as humanData from "./js/humanData.js";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      rowStyle: this.$rowStyle,
      whetherAgencyHead: false, // 是否是机构负责人
      loading: true,
      buttonLoading: false,
      searchData: {
        input: "",
        checked: false,
        userId: "",
        userList: [],
        userList2: [],
        tempUserId: [],
        departTreeData: [],
        department: "",
        dataDate: this.$formatTime(
          new Date() - 31 * 24 * 3600 * 1000,
          "YYYY-MM"
        ),
        allStepTime: this.$formatTime(new Date(), "YYYY-MM"),
        clientName: "", // 客户名称
        productList: [],
        receiptsStatus: "",
        chargeUpStatus: "",
        checkStatus: "",
        clientType: "",
      },
      tabindex: 1,
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tableMenuData: humanData.getHumanContractMenuData(),
      tableMenuData2: humanData.getHumanContractMenuData2(),
      tableData: [],
      allMoney: "", // 签约总金额
      allPaymentCollect: "", // 收款总金额
      allAmountsOw: "", // 欠款总金额
      allProfits: "", // 总利润
      allCost: "", // 合同成本
      allRoyalty: "", // 合计提成
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "humanContract"
      ),
      tempData: {},
      multipleSelection: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增档案",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      editFlag: true,
    };
  },

  methods: {
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "humanContract",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.editFlag = !this.allFunctionButtons.some(
            (item) => item.key == "edit" && item.authority == "1"
          );
          console.log("当前显示的按钮权限", data);
          this.getEmployeeListByUserId();
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListByUserId();
      });
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList2 = JSON.parse(JSON.stringify(data));
          this.searchData.userList = JSON.parse(JSON.stringify(data));
          this.searchData.userId = "all";
          this.tempAllEmpolyeeList = [];
          this.searchData.userList.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.seleteInitTableData("all");
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有业务角色列表
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.searchData.roleList = data;

          this.getAccountList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有收款账号
     */
    getAccountList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/companyAccount/getAccountList",
        requestData,
        (data) => {
          this.searchData.accountList = [];
          let dd = {};
          data.forEach((element) => {
            let account = "";
            element.accountList.forEach((ele) => {
              account =
                account == "" ? ele.account : account + "," + ele.account;
            });

            dd = {
              owner: element.owner,
              account: account,
            };
            this.searchData.accountList.push(dd);
          });

          console.log("获取所有收款账号:", this.searchData.accountList);
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getAllProduct();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有产品
     */
    getAllProduct() {
      this.searchData.productList = [];
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/servicesItem/getServeTypeAndItem",
        requestData,
        (data) => {
          this.searchData.productList = data;
        }
      );
    },
    initTableData() {
      let requestData = {};
      let url = "";
      this.loading = true;
      if (this.tabindex == 1) {
        url = "/contractCommission/page";
      } else {
        url = "/contractCommission/statistics";
      }
      requestData = {
        clientName: this.searchData.clientName,
        userIds: this.searchData.tempUserId,
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        dataDate: this.searchData.dataDate, // 日期
        chargeUpStatus: this.searchData.chargeUpStatus, // 记账状态
        checkStatus: this.searchData.checkStatus, // 审核状态
        clientType: this.searchData.clientType, // 公司类型
        receiptsStatus: this.searchData.receiptsStatus, // 收款状态
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        url,
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    handleClick(data, flag, prop) {
      this.tempData = JSON.parse(JSON.stringify(data));
      this.tempData.prop = prop;
      let requestData = {};
      switch (flag) {
        case 0:
          // 合同提成同步
          this.dialogData.width = "500px";
          this.dialogData.title = "合同提成同步";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "同步产品",
              type: "cascader-tree",
              value: [],
              options: this.searchData.productList,
              props: {
                checkStrictly: false,
                emitPath: false,
                multiple: true,
                label: "name",
                value: "id",
              },
              collapse_tags: true,
            },
            {
              title: "同步月份",
              type: "time_month",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 批量修改提成比例
          this.dialogData.width = "500px";
          this.dialogData.title = "提成比例修改";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "提成比例",
              type: "input-number2",
              value: "",
              suffix_icon: "%",
            },
          ];
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_add":
          // 同步产品
          if (this.dialogData.data[0].value.length <= 0) {
            this.$message.error("请选择同步产品!");
            return;
          }
          if (!this.dialogData.data[1].value) {
            this.$message.error("请选择同步日期!");
            return;
          }
          this.buttonLoading = true;
          this.$message.success("正在同步, 请耐心等待!");
          this.$set(this.dialogData.buttonArray[0], "loading", true);
          requestData = {
            productIds: this.dialogData.data[0].value, // 产品 id 集合
            dataDate: this.dialogData.data[1].value, // 日期
          };
          this.$request(
            "post",
            "/contractCommission/backupCopy",
            requestData,
            (data) => {
              this.$message.success("同步成功!");
              this.initTableData();
              this.buttonLoading = false;
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.buttonLoading = false;
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_edit":
          // 批量修改提成比例
          requestData = {
            clientName: this.searchData.clientName,
            userIds: this.searchData.tempUserId,
            department:
              this.searchData.department.length > 0
                ? this.searchData.department[
                    this.searchData.department.length - 1
                  ]
                : "",
            ratio: this.dialogData.data[0].value, // 比率
            dataDate: this.searchData.dataDate, // 数据日期，格式：yyyy-MM
            fieldName: this.$toLowerCase(this.tempData.prop), // 修改的属性名，小驼峰命名风格变成下划线
          };
          if (!requestData.ratio) {
            this.$message.error("请填写修改比例!");
            return;
          }
          this.$set(this.dialogData.buttonArray[0], "loading", true);
          this.$request(
            "post",
            "/contractCommission/updateRatioBatch",
            requestData,
            (data) => {
              this.$message.success("编辑成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * 修改详情数据
     */
    changeItem(value, row, prop) {
      let requestData = {
        id: row.id,
        contractId: row.contractId,
      };
      requestData[prop] = value;
      let flag = this.$numberReg(value);
      if (!flag) {
        this.$message.error("请填写数字再进行编辑!");
        return;
      }
      this.$request(
        "post",
        "/contractCommission/update",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    seleteInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    changeTab(index) {
      this.tabindex = index;
      this.resetPage();
    },
    handleCurrentChange(val) {
      this.initTableData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    getTableChildrenStyle(data) {
      let row = data.row;
      let style = { height: "65px", backgroundColor: "#ffffff" };
      if (row.onlyChildrenHave) {
        style.backgroundColor = "#ebeef5";
      }
      return style;
    },
    /**
     * 表格多选事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "合计";
          return;
        }
        if (this.tabindex == 1) {
          if (
            index !== 8 &&
            index !== 11 &&
            index !== 14 &&
            index !== 17 &&
            index !== 20 &&
            index !== 23
          ) {
            sums[index] = "";
            return;
          }
        } else {
          if (index == 0 || index == 1) {
            sums[index] = "";
            return;
          }
        }

        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },

    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.searchData.userId = "all";
    this.searchData.roleUserId = "all";
    // this.searchData.tempUserId = ["all"];
    this.getModelFeatureAuthority();
    this.isCharge();
  },
};
</script>

<style lang="less" scoped>
#human-contract {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .human-contract-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    flex-direction: column;

    .human-contract-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      .el-checkbox-group {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .human-contract-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }

      .human-contract-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }

      .human-contract-screen-search-left {
        margin-left: 0;
      }
      .human-contract-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        transition: 0.5s;
        &:hover {
          background-color: #abbed3;
        }
      }
      .human-contract-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .human-contract-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .human-contract-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .human-contract-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .human-contract-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .human-contract-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .human-contract-screen-but-boder {
        padding: 0 15px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .human-contract-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .human-contract-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .human-contract-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .human-contract-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .human-contract-table-card-tab {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .human-contract-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .human-contract-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .human-contract-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .human-contract-table-card-content {
        flex: 1;
        width: 98%;
        .human-contract-table-card-content_text {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0px;
          box-sizing: border-box;
          color: rgb(99, 211, 34);
          font-size: 12px;
        }

        /deep/ .el-input__inner {
          width: 25px;
          padding: 0;
          margin: 0;
          color: #333;
          cursor: text;
          border: 1px solid transparent;
          background-color: transparent;
          text-align: right;
        }

        /deep/ .el-input {
          width: 70%;
        }
        /deep/ .el-table--scrollable-y .el-table__body-wrapper {
          z-index: 1;
        }
      }
      .human-contract-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
  .link {
    color: @activeColor;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .el-date-editor.el-input__inner {
    width: 300px !important;
  }
  .el-table__row--level-1 {
    background-color: #dddddd !important;
  }
}
</style>
