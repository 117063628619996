<template>
  <div id="index">
    <div class="content">
      <div class="left">
        <!-- 公告栏 -->
        <div class="notice-board">
          <div class="notice-title">
            <div class="title1">
              <div><img :src="noticeTitleimg" alt="" /></div>
              <div>公告栏</div>
            </div>
          </div>
          <div class="notice-content">
            <div
              class="item-content1"
              v-for="(item, id) in boardList"
              :key="id"
              @click="noticeClick(item)"
            >
              <!-- <div class="affiche"> -->
              <div class="affiche-img-word">
                <!-- <div class="affiche-img"><img :src="noticeimg" alt="" /></div> -->
                <div class="affiche-word">{{ item.title | controlWord }}</div>
              </div>
              <div class="affiche-time">{{ item.gmtCreate }}</div>
              <!-- </div> -->
            </div>
          </div>
        </div>

        <!-- 知识库 -->
        <div class="knowledge-base">
          <div class="knowledge-title">
            <div class="title2">
              <div><img :src="knowledgeTitleimg" alt="" /></div>
              <div>知识库</div>
            </div>
          </div>
          <div class="knowledge-content">
            <div
              class="item-content2"
              v-for="(item, id) in knowledgeList"
              :key="id"
              @click="knowledgeClick(id)"
            >
              <!-- <div class="knowledge"> -->
              <div class="knowledge-img-word">
                <div class="knowledge-word">
                  {{ item.title | controlWord }}
                </div>
              </div>
              <div class="knowledge-time">{{ item.time }}</div>
              <!-- </div> -->
            </div>
          </div>
        </div>

        <!-- 快捷入口 -->
        <div class="shortcut-key">
          <div class="shortcut-title">
            <div class="title3">
              <div><img :src="shortcutimg" alt="" /></div>
              <div>快捷入口</div>
            </div>
          </div>
          <div class="shortcut-content">
            <div class="enter">
              <div class="common">
                <div
                  class="item"
                  v-for="(item, index) in enterlist"
                  :key="index"
                  @click="enterClick(item)"
                >
                  <!-- <img :src="item.enterimg" />
                    <div>{{ item.entername }}</div> -->
                  <div
                    class="item-img"
                    :style="{
                      backgroundColor: shortcutBackgroundColor[index % 8],
                    }"
                  >
                    <img :src="item.enterimg" />
                  </div>
                  <div class="item-text">
                    {{ item.entername }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="center">
        <!-- 柱状图 -->
        <div class="histogram">
          <div class="chartTitle">
            <div class="chart-title1">{{ nowYear }}年销售数据分析</div>
          </div>
          <!-- <select name="" id="">
            <option value=""></option>
            <option value=""></option>
            <option value=""></option>
          </select> -->
          <div id="columnar"></div>
        </div>
        <div class="center_active"></div>
        <!-- 折线图 -->
        <div class="lineChart">
          <div class="chartTitle">
            <div class="chart-title2">{{ nowYear }}年个人销售数据分析</div>
          </div>
          <div id="brokenLine"></div>
        </div>
      </div>

      <div class="right">
        <!-- 排行榜 -->
        <div class="ranking-list">
          <div class="ranking-title">
            <div class="ranking-word">
              {{ nowYear }}年{{ nowMonth }}月份销售业绩排行榜
            </div>
            <div class="ranking-switch"></div>
          </div>
          <div class="ranking-rank list-name">
            <div class="ranking-rank">排名</div>
            <div class="ranking-name">姓名</div>
            <div class="ranking-results">业绩</div>
          </div>
          <div class="ranking-content">
            <div
              class="ranking-module"
              v-for="(item, index) in rankingList"
              :key="index"
            >
              <!-- 名次 -->
              <div class="ranking-rank">
                <div class="rank-color">{{ index + 1 }}</div>
              </div>
              <div class="ranking-name">{{ item.singer }}</div>
              <div class="ranking-results">{{ item.orderSum.toFixed(1) }}</div>
            </div>
          </div>
        </div>
        <!-- 待审核事务 -->
        <div class="schedule">
          <div class="schedule-title">
            <div>待办事务</div>
          </div>
          <div class="schedule-content">
            <div
              class="schedule-part"
              v-for="(part, part_index) in schedulePartList"
              :key="part_index"
            >
              <div class="schedule-part-top">
                <div
                  class="schedule-part-top-img"
                  :style="{
                    backgroundColor: shortcutBackgroundColor[part_index % 8],
                  }"
                >
                  <img :src="part.img" alt="" />
                </div>
                <div class="schedule-part-top-title">
                  {{ part.title }}
                </div>
                <div class="schedule-part-top-refresh">
                  <i class="el-icon-refresh-right"></i>
                </div>
              </div>
              <div class="schedule-part-list">
                <div
                  class="schedule-part-list-item"
                  v-for="(item, index) in part.list"
                  :key="index"
                >
                  {{ item.name | controlWord }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @dialogOper="drawerOper"
        @colseDialog="colseDialog"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  // 超过十八个字显示'...'
  filters: {
    controlWord(value) {
      if (!value) return "";
      if (value.length > 18) {
        return value.slice(0, 18) + "...";
      }
      return value;
    },
  },

  data() {
    return {
      shortcutBackgroundColor: [
        "#72cd6a",
        "#ff2345",
        "#9163fe",
        "#fdd187",
        "#195ffe",
        "#ffa007",
        "#ff706f",
        "#729bff",
      ],
      schedulePartList: [
        {
          title: "发票任务",
          img: require("../assets/img/fapiaodengji.png"),
          list: [
            {
              id: "",
              name: "增值税电子普通发票1",
            },
            {
              id: "",
              name: "增值税电子普通发票(通行费)2",
            },
            {
              id: "",
              name: "增值税电子普通发票3",
            },
          ],
        },
        {
          title: "合同审核",
          img: require("../assets/img/icon_hetongshenhe.png"),
          list: [
            {
              id: "",
              name: "XX建筑公司合同审核1",
            },
            {
              id: "",
              name: "XX科技公司合同审核2",
            },
            {
              id: "",
              name: "XX建筑公司合同审核3",
            },
          ],
        },
        {
          title: "客户流失",
          img: require("../assets/img/liushihuiyuan.png"),
          list: [
            {
              id: "",
              name: "客户1流失",
            },
            {
              id: "",
              name: "客户2流失",
            },
            {
              id: "",
              name: "客户3流失",
            },
          ],
        },
        {
          title: "工作任务",
          img: require("../assets/img/gongzuorenwu.png"),
          list: [
            {
              id: "",
              name: "增值税电子普通发票1",
            },
            {
              id: "",
              name: "增值税电子普通发票(通行费)2",
            },
            {
              id: "",
              name: "增值税电子普通发票3",
            },
          ],
        },
      ],
      baseInfo: {},
      nowYear: "",
      nowMonth: "",
      // 公告栏
      noticeTitleimg: require("../assets/img/noticeTitle.png"),
      noticeimg: require("../assets/img/notice.png"),
      boardList: [
        {
          id: "1",
          title: "合肥注册公司刻章需要注意哪些问题?",
          time: "2021-07-30 13:22:54",
        },
        {
          id: "2",
          title: "汇算清缴,12项费用税前扣除比例",
          time: "2021-07-30 13:22:54",
        },
        {
          id: "3",
          title: "企业找代账公司代账客户需要交接哪些资料说明?",
          time: "2021-07-30 13:22:54",
        },
        {
          id: "4",
          title: "合肥注册公司刻章需要哪些资料说明?",
          time: "2021-07-30 13:22:54",
        },
        {
          id: "2",
          title: "汇算清缴,12项费用税前扣除比例",
          time: "2021-07-30 13:22:54",
        },
        {
          id: "3",
          title: "企业找代账公司代账客户需要交接哪些资料说明?",
          time: "2021-07-30 13:22:54",
        },
      ],

      // 知识库
      knowledgeTitleimg: require("../assets/img/knowledgeBaseTitle.png"),
      knowledgeimg: require("../assets/img/knowledgeBase.png"),
      knowledgeList: [
        {
          id: "1",
          title: "合肥注册公司刻章需要注意哪些问题?",
          content: "第一条公告内容",
          time: "2021-07-30 13:22:54",
        },
        {
          id: "2",
          title: "汇算清缴,12项费用税前扣除比例",
          content: "第二条公告内容",
          time: "2021-07-30 13:22:54",
        },
        {
          id: "3",
          title: "企业找代账公司代账客户需要交接哪些资料说明?",
          content: "第二条公告内容",
          time: "2021-07-30 13:22:54",
        },
        {
          id: "4",
          title: "合肥注册公司刻章需要哪些资料说明?",
          content: "第二条公告内容",
          time: "2021-07-30 13:22:54",
        },
        {
          id: "2",
          title: "汇算清缴,12项费用税前扣除比例",
          content: "第二条公告内容",
          time: "2021-07-30 13:22:54",
        },
        {
          id: "3",
          title: "企业找代账公司代账客户需要交接哪些资料说明?",
          content: "第二条公告内容",
          time: "2021-07-30 13:22:54",
        },
      ],

      // 快捷入口
      shortcutimg: require("../assets/img/shortcut.png"),
      enterlist: [
        {
          enterimg: require("../assets/img/contract.png"),
          entername: "签订合同",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/paymentclient.png"),
          entername: "未回款客户",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/clue.png"),
          entername: "新增商机",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/waitclient.png"),
          entername: "客户出访",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/journaling.png"),
          entername: "财务报表",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/rankinglist.png"),
          entername: "排行榜",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/register.png"),
          entername: "回款登记",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/contract.png"),
          entername: "签订合同",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/paymentclient.png"),
          entername: "未回款客户",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/clue.png"),
          entername: "新增商机",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/waitclient.png"),
          entername: "客户出访",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/journaling.png"),
          entername: "财务报表",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/rankinglist.png"),
          entername: "排行榜",
          enterhref: "",
        },
        {
          enterimg: require("../assets/img/register.png"),
          entername: "回款登记",
          enterhref: "",
        },
      ],
      rankList: "",
      // 业绩排行榜
      rankingList: [],

      // 待审核事务
      // involiceContent: [],
      updateimg: require("../assets/img/update.png"),
      involiceList: [
        {
          involiceContent: "发票任务1",
          involiceIcon: require("../assets/img/agenda.png"),
        },
        {
          involiceContent: "发票任务2",
          involiceIcon: require("../assets/img/agenda.png"),
        },
        {
          involiceContent: "发票任务3",
          involiceIcon: require("../assets/img/agenda.png"),
        },
        {
          involiceContent: "发票任务4",
          involiceIcon: require("../assets/img/agenda.png"),
        },
        {
          involiceContent: "发票任务5",
          involiceIcon: require("../assets/img/agenda.png"),
        },
        {
          involiceContent: "发票任务6",
          involiceIcon: require("../assets/img/agenda.png"),
        },
      ],
      auditList: [
        {
          auditContent: "合同审核1",
          auditIcon: require("../assets/img/agenda.png"),
        },
        {
          auditContent: "合同审核2",
          auditIcon: require("../assets/img/agenda.png"),
        },
        {
          auditContent: "合同审核3",
          auditIcon: require("../assets/img/agenda.png"),
        },
        {
          auditContent: "合同审核4",
          auditIcon: require("../assets/img/agenda.png"),
        },
        {
          auditContent: "合同审核5",
          auditIcon: require("../assets/img/agenda.png"),
        },
        {
          auditContent: "合同审核6",
          auditIcon: require("../assets/img/agenda.png"),
        },
        {
          auditContent: "合同审核7",
          auditIcon: require("../assets/img/agenda.png"),
        },
      ],
      customerList: [
        {
          customerContent: "客户流失1",
          customerIcon: require("../assets/img/agenda.png"),
        },
        {
          customerContent: "客户流失2",
          customerIcon: require("../assets/img/agenda.png"),
        },
        {
          customerContent: "客户流失3",
          customerIcon: require("../assets/img/agenda.png"),
        },
        {
          customerContent: "客户流失4",
          customerIcon: require("../assets/img/agenda.png"),
        },
        {
          customerContent: "客户流失5",
          customerIcon: require("../assets/img/agenda.png"),
        },
        {
          customerContent: "客户流失6",
          customerIcon: require("../assets/img/agenda.png"),
        },
      ],
      taskList: [
        {
          taskContent: "工作任务1",
          taskIcon: require("../assets/img/agenda.png"),
        },
        {
          taskContent: "工作任务2",
          taskIcon: require("../assets/img/agenda.png"),
        },
        {
          taskContent: "工作任务3",
          taskIcon: require("../assets/img/agenda.png"),
        },
        {
          taskContent: "工作任务4",
          taskIcon: require("../assets/img/agenda.png"),
        },
        {
          taskContent: "工作任务5",
          taskIcon: require("../assets/img/agenda.png"),
        },
        {
          taskContent: "工作任务6",
          taskIcon: require("../assets/img/agenda.png"),
        },
        {
          taskContent: "工作任务7",
          taskIcon: require("../assets/img/agenda.png"),
        },
        {
          taskContent: "工作任务8",
          taskIcon: require("../assets/img/agenda.png"),
        },
      ],

      //  待办事项 开票 待办合同 将到期合同
      scheduleList: [
        {
          agendaimg: require("../assets/img/makeOutAnInvoice.png"),
          number: "3",
          fontSize: "1.5vw",
          agenda: "开票",
          backgroundColor: "rgb(251, 217, 224)",
        },
        {
          agendaimg: require("../assets/img/agenda.png"),
          number: "5",
          fontSize: "1.5vw",
          agenda: "待办合同",
          backgroundColor: "rgb(217, 243, 166)",
        },
        {
          agendaimg: require("../assets/img/maturity.png"),
          number: "6",
          fontSize: "1.5vw",
          agenda: "将到期合同",
          backgroundColor: "rgb(156, 245, 245)",
        },
      ],

      notice: {},
      knowledges: {},
      loading: true,
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      clientAreaOptions: [],
      allEmployeeList: [],
      allThreadList: [],
      visitReasonList: [],
    };
  },
  methods: {
    /**
     * 获取年销售数据据分析
     */
    annualSalesData() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };

      this.$request(
        "post",
        "/index/annualSalesData",
        requestData,
        (data) => {
          this.employeesInSalesData();
          this.Histogram(data);
          this.loading2 = false;
          this.loading.close();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // 柱状图
    Histogram(data) {
      let list = [];
      if (data.length == 0) {
        list = [
          // ["product", "商机总数", "签约客户数", "未回款客户数"],
          ["1月", 0, 0, 0],
          ["2月", 0, 0, 0],
          ["3月", 0, 0, 0],
          ["4月", 0, 0, 0],
          ["5月", 0, 0, 0],
          ["6月", 0, 0, 0],
          ["7月", 0, 0, 0],
          ["8月", 0, 0, 0],
          ["9月", 0, 0, 0],
          ["10月", 0, 0, 0],
          ["11月", 0, 0, 0],
          ["12月", 0, 0, 0],
        ];
      } else {
        data.forEach((element, i) => {
          list.push([
            i + 1 + "月",
            element.thread,
            element.subscribers,
            element.notReceivableCustomer,
          ]);
        });
      }
      let myChart = this.$echarts.init(document.getElementById("columnar"));

      myChart.setOption({
        legend: {},
        tooltip: {},
        dataset: {
          source: list,
        },
        grid: {
          left: "3%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: { type: "category" },
        yAxis: {},
        series: [
          {
            type: "bar",
            name: "商机总数",
          },
          {
            type: "bar",
            name: "签约客户数",
          },
          {
            type: "bar",
            name: "未回款客户数",
          },
        ],
      });
    },

    /**
     * 获取员工日商机总数
     */
    employeesInSalesData() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/index/employeesInSalesData",
        requestData,
        (data) => {
          this.teamClick("0");
          this.LineChart(data);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    // 折线图
    LineChart(data) {
      let myChart = this.$echarts.init(document.getElementById("brokenLine"));
      let list = [];
      let xList = [];
      if (data.length == 0) {
        for (let index = 1; index < 31; index++) {
          xList.push(index + "月");
          list.push(Math.ceil(Math.random() * 30) + 20);
        }
      } else {
        data.forEach((element, i) => {
          list.push(element);
          xList.push(i + 1 + "月");
        });
      }
      myChart.setOption({
        title: {
          // text: "个人月销售数据分析",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["团队1"],
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
          },
        ],
        grid: {
          left: "3%",
          right: "2%",
          bottom: "11%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xList,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "line",
            data: list,
            areaStyle: {
              color: "rgba(208,234,251, 0.8)",
            },
            smooth: true,
          },
        ],
      });
    },

    //年度与本月业绩排行榜切换
    teamClick(flag) {
      this.rankList = flag;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/index/orderRanking",
        requestData,
        (data) => {
          this.rankingList = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    // 公告栏点击事件
    noticeClick(item) {
      this.dialogData.width = "55%";
      this.dialogData.title = "公告详情";
      this.dialogData.buttonArray = [];
      this.dialogData.data = [
        {
          type: "notice_detail",
          data: item,
        },
      ];
      this.dialogData.flag = true;
    },

    // 知识库点击事件
    knowledgeClick(id) {},

    /**
     * 获取所有公告
     */
    getNoticeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 公司id
        // startTime: "", // 时间，年月日
        // endTime: "", // 时间，年月日
        // search: "", // 标题或内容，空则为全部
        // status: "1", // 状态 0-全部 1-正常
        currentPage: 1, // 当前页数
        pageSize: 6, // 每页条数
      };
      this.boardList = [];
      this.$request(
        "post",
        "/notice/page",
        requestData,
        (data) => {
          this.boardList = data.records;
          this.getLibraryList();
        },
        (err) => {
          this.$message.error(err);
        }
      );
    },
    /**
     * 获取所有知识库
     */
    getLibraryList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 公司id
        startTime: "", // 时间，年月日,可空
        endTime: "", // 时间，年月日,可空
        search: "", // 标题或摘要或附加内容，空则为全部
        typeName: "", // 分组名称，空则为全部
        type: "", // 类型，空则为全部
        currentPage: 1, // 当前页数
        pageSize: 6, // 每页条数
      };
      this.knowledgeList = [];
      this.$request(
        "post",
        "/knowledgeBase/getLibraryList",
        requestData,
        (data) => {
          this.knowledgeList = data.tableData;
        },
        (err) => {
          this.$message.error(err);
        }
      );
    },
    /**
     * 获取所有在职员工
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.allEmployeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.allEmployeeList.push(dd);
          });
          this.getClientAreaOptions();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取客户片区
     */
    getClientAreaOptions() {
      let requestData = {
        type: ["片区", "出访事由"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.type == "片区") {
              this.clientAreaOptions = element.value.map((item) => {
                return {
                  label: item.name,
                  value: item.value,
                };
              });
            } else if (element.type == "出访事由") {
              this.visitReasonList = element.value.map((item) => {
                return {
                  label: item.name,
                  value: item.value,
                };
              });
            }
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_add":
          requestData = {
            threadId: "", // 商机 id
            contactPerson: "", // 联系人
            contactNumber: "", // 联系电话
            visitArea: "", // 出访区域
            visitAddress: "", // 出访详细地址
            visitTimeStart: "", // 出访时间，yyyy-MM-dd HH:mm:ss
            duration: "", // 出访时长，
            registeredUserId: this.baseInfo.userId, // 登记用户
            accompanyUserId: "", // 陪访用户
          };
          for (let index = 0; index < this.dialogData.data.length; index++) {
            const element = this.dialogData.data[index];
            if (element.key == "accompanyUserId") {
              requestData[element.key] = JSON.stringify(
                element.value.map((item) => item.value)
              );
            } else if (element.key == "visitTime") {
              requestData.visitTimeStart =
                element.value1 + " " + element.value2 + ":00";
              requestData.duration = element.value3;
            } else {
              requestData[element.key] = element.value;
            }
          }
          for (const key in requestData) {
            if (
              requestData[key] === null ||
              requestData[key] === undefined ||
              requestData[key] === ""
            ) {
              this.$message.error("请填写完整信息!");
              return;
            }
          }
          let flag1 = true;
          if (
            !this.$phoneCheck.mobilePhoneNumberCheck(requestData.contactNumber)
          ) {
            // 做手机号格式校验
            flag1 = false;
          }
          if (!flag1) {
            this.$message.error(
              "联系人手机号格式不正确,请确认填入正确的手机号!"
            );
            return;
          }
          this.$request(
            "post",
            "/visitRegistration/add",
            requestData,
            (data) => {
              this.$message.success("出访记录添加成功!");
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    enterClick(item) {
      let requestData = {};
      switch (item.entername) {
        case "客户出访":
          let options = [];
          for (let index = 1; index <= 23; index++) {
            options[index - 1] = {
              label: index + "小时",
              value: index,
            };
          }
          options.push(
            { label: "1天", value: 24 },
            { label: "2天", value: 48 },
            { label: "3天", value: 72 }
          );
          this.dialogData.width = "600px";
          this.dialogData.title = "出访记录添加";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "客户名称",
              key: "threadId",
              type: "select-filterable",
              value: "",
              options: [],
            },
            {
              title: "联系人",
              key: "contactPerson",
              type: "input",
              value: "",
            },
            {
              title: "手机号",
              key: "contactNumber",
              type: "input",
              value: "",
            },
            {
              title: "出访时间",
              key: "visitTime",
              type: "timess",
              value1: this.$formatTime(new Date(), "YYYY-MM-DD"),
              value2: "",
              value3: "",
              options: options,
            },
            {
              title: "出访事由",
              key: "visitArea",
              type: "select",
              value: "",
              options: this.visitReasonList,
            },
            {
              title: "出访地区",
              key: "visitArea",
              type: "select",
              value: "",
              options: this.clientAreaOptions,
            },
            {
              title: "详细地址",
              key: "visitAddress",
              type: "input",
              value: "",
            },
            {
              title: "陪访人员",
              key: "accompanyUserId",
              type: "select-object",
              value: "",
              options: this.allEmployeeList,
            },
          ];
          console.log("新增dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
  },

  mounted() {
    this.nowYear = this.$formatTime(new Date(), "YYYY");
    this.nowMonth = this.$formatTime(new Date(), "M");
    let dd = ["1", "2", "3", "4", "5", "6", "7"];
    // if (this.$formatTime(new Date(), "D") < 8) {
    //   this.nowMonth = this.nowMonth - 1;
    // }
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.annualSalesData();
    this.LineChart([]);
    this.getNoticeList();
    this.getEmployeeListForDownBox();
  },

  created() {
    this.loading = this.$loading({
      lock: true, //lock的修改符--默认是false
      text: "Loading", //显示在加载图标下方的加载文案
      spinner: "el-icon-loading", //自定义加载图标类名
      background: "rgba(0, 0, 0, 0.7)", //遮罩层颜色
      target: document.querySelector("#table"), //loadin覆盖的dom元素节点
    });
  },
  // computed: {
  //   activeStyle() {
  //     return this.isActive ? { color: this.activeColor } : {};
  //   },
  // },
};
</script>

<style lang="less" scoped>
#index {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f3f3f3;
  justify-content: center;
  .content {
    width: 98%;
    margin-top: 10px;
    height: 98%;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // background-color: antiquewhite;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 28%;
      height: 100%;
      // border-right: #e0dede 2px solid;
      // box-sizing: border-box;
      border-radius: 3px;
      .notice-board {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

        .notice-title {
          width: 100%;
          height: 16%;
          display: flex;
          box-sizing: border-box;
          background-color: @indexTitleBachgroundColor;
          // border-left: 3px solid #40a3ff;
          font-size: 0.9vw;
          color: rgb(65, 64, 64);
          font-weight: bolder;
          align-items: center;
          .title1 {
            width: 25%;
            height: 100%;
            display: flex;
            cursor: pointer;
            align-items: center;
            > div:nth-child(1) {
              width: 17%;
              display: flex;
              align-items: flex-end;
              margin: 0 10px;
              img {
                width: 100%;
              }
            }
          }
        }
        .notice-content {
          width: 100%;
          height: 80%;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 1px;
          }
          .item-content1 {
            width: 100%;
            display: flex;
            flex-shrink: 0;
            height: 14%;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;
            .affiche-img-word {
              width: 55%;
              height: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-shrink: 0;
              .affiche-img {
                width: 10%;
                img {
                  width: 100%;
                }
              }
              .affiche-word {
                width: 88%;
                font-size: 0.7vw;
                display: flex;
                cursor: pointer;
              }
            }
            .affiche-time {
              width: 35%;
              font-size: 0.6vw;
              color: #979494;
              display: flex;
              justify-content: flex-end;
              // background-color: rgb(183, 202, 72);
            }
          }
          .item-content1:hover {
            background-color: #40a3ff18;
            color: #40a3ff;
          }
          > div:nth-child(1) {
            margin-top: 8px;
          }
        }
      }
      .knowledge-base {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        // border-bottom: 2px solid darkgray;
        // border-top: 2px solid darkgray;
        .knowledge-title {
          width: 100%;
          height: 16%;
          display: flex;
          box-sizing: border-box;
          background-color: @indexTitleBachgroundColor;
          // border-left: 3px solid #40a3ff;
          font-size: 0.9vw;
          color: rgb(65, 64, 64);
          font-weight: bolder;
          align-items: center;
          .title2 {
            width: 25%;
            height: 100%;
            display: flex;
            cursor: pointer;
            align-items: center;
            > div:nth-child(1) {
              width: 17%;
              display: flex;
              align-items: flex-end;
              margin: 0 10px;
              img {
                width: 100%;
              }
            }
            // > div:nth-child(2) {
            // }
          }
        }
        .knowledge-content {
          width: 100%;
          height: 80%;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 1px;
          }
          .item-content2 {
            width: 100%;
            display: flex;
            flex-shrink: 0;
            height: 14%;
            align-items: center;
            justify-content: space-evenly;
            // .knowledge{
            //   width: 100%;
            //   height: 100%;
            //   display: flex;
            //   margin-bottom: 10px;
            //   justify-content: space-between;
            .knowledge-img-word {
              width: 55%;
              height: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-shrink: 0;
              .knowledge-img {
                width: 10%;
                img {
                  width: 100%;
                }
              }
              .knowledge-word {
                width: 88%;
                font-size: 0.7vw;
                display: flex;
                cursor: pointer;
              }
            }
            .knowledge-time {
              width: 35%;
              font-size: 0.6vw;
              color: #979494;
              display: flex;
              justify-content: flex-end;
            }
          }
          .item-content2:hover {
            background-color: #40a3ff18;
            color: #40a3ff;
          }
          > div:nth-child(1) {
            margin-top: 8px;
          }
        }
      }
      .shortcut-key {
        width: 100%;
        height: 38%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        .shortcut-title {
          width: 100%;
          height: 16%;
          display: flex;
          box-sizing: border-box;
          background-color: @indexTitleBachgroundColor;
          // border-left: 3px solid #40a3ff;
          font-size: 0.9vw;
          color: rgb(65, 64, 64);
          font-weight: bolder;
          align-items: center;
          .title3 {
            width: 30%;
            height: 100%;
            display: flex;
            cursor: pointer;
            // justify-content: space-around;
            align-items: center;
            > div:nth-child(1) {
              width: 17%;
              display: flex;
              align-items: flex-end;
              margin: 0 10px;
              img {
                width: 100%;
              }
            }
            // > div:nth-child(2) {
            // }
          }
        }
        .shortcut-content {
          width: 100%;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          .enter {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            .common {
              width: 100%;
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              // justify-content: flex-start;
              overflow-y: auto;
                &::-webkit-scrollbar  {
                width: 1px;
              }
              .item {
                // flex: 1;
                width: 25%;
                height: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 10px;
                transition: 0.2s;
                &:hover {
                  cursor: pointer;
                  color: @activeColor;
                }
                .item-img {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    width: 60%;
                    margin-top: 5%;
                    margin-bottom: 10%;
                  }
                }
                .item-text {
                  width: 100%;
                  height: 30px;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      border-radius: 5px;
      .histogram,
      .lineChart {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 0;
        flex: 1;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        .chartTitle {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-weight: bolder;
          background-color: @indexTitleBachgroundColor;
          font-size: 0.9vw;
          color: rgb(65, 64, 64);
          width: 100%;
          height: 10%;
          //  {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   width: 25%;
          //   height: 100%;
          //   border-left: 3px solid #40a3ff;
          // }
          .chart-title1,
          .chart-title2 {
            display: flex;
            justify-content: flex-start;
            padding-left: 2%;
            align-items: center;
            width: 0;
            flex: 1;
            height: 100%;
            box-sizing: border-box;
            // border-left: 3px solid #40a3ff;
          }
        }
        #columnar,
        #brokenLine {
          width: 95%;
          height: 90%;
        }
      }
      .center_active {
        height: 10px;
      }
    }
    //右边模块
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 20%;
      height: 100%;
      // border-left: #e0dede 2px solid;
      // 业绩排行榜
      .ranking-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 49%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        // 标题
        .ranking-title {
          display: flex;
          width: 100%;
          height: 10%;
          justify-content: center;
          align-items: center;
          // border-left: 3px solid #40a3ff;
          background-color: @indexTitleBachgroundColor;
          box-sizing: border-box;
          // 标题内容
          .ranking-word {
            display: flex;
            width: 90%;
            justify-content: center;
            font-weight: bolder;
            font-size: 0.9vw;
            color: rgb(65, 64, 64);
          }
          // 个人/团队切换文字
          .ranking-switch {
            display: flex;
            width: 20%;
            height: 80%;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .ranking-team,
            .ranking-person {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40%;
              height: 100%;
              font-size: 0.8vw;
            }
            .separator {
              width: 10%;
              height: 80%;
              display: flex;
              // background-color: #979494;
              // font-weight: bold;
              font-size: 1vw;
              justify-content: center;
              align-items: center;
              // border-right: #979494 1px solid;
            }
            .ranking-team:hover {
              color: tomato;
            }
            .ranking-person:hover {
              color: tomato;
            }
            .ranking-active {
              color: tomato;
            }
          }
        }
        // 排行榜 名次/姓名/部门/业绩
        .list-name {
          display: flex;
          width: 100%;
          height: 9%;
          border-top: #f3f3f3 1px solid;
          font-size: 0.8vw;
          // font-weight: bolder;
          // color: rgb(65, 64, 64);
          // color: #f3f3f3;
          background-color: #d3d4d694;
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
          }
        }
        // 内容
        .ranking-content {
          display: flex;
          width: 100%;
          height: 80%;
          flex-direction: column;
          cursor: pointer;
          overflow-y: auto;
          // 滚动条隐藏
          &::-webkit-scrollbar {
            width: 1px;
          }
          // 名次,姓名
          .ranking-module {
            display: flex;
            width: 100%;
            height: 10%;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            font-size: 0.8vw;
            .ranking-rank,
            .ranking-name,
            .ranking-team,
            .ranking-results {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 0;
              flex: 1;
              height: 100%;
              .rank-color {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20%;
                height: 55%;
                border-radius: 5px;
                color: #fff;
                background-color: rgba(114, 108, 108, 0.781);
              }
            }
          }

          .ranking-module:hover {
            background-color: #40a3ff18;
          }
          // 前三名标红
          .ranking-module:nth-child(1) {
            // font-size: 0.9vw;
            font-weight: bold;
            color: rgb(248, 51, 16);
            .rank-color:nth-child(1) {
              background-color: crimson;
            }
          }
          .ranking-module:nth-child(2) {
            font-weight: bold;
            color: rgb(248, 51, 16);
            .rank-color:nth-child(1) {
              background-color: rgb(241, 112, 26);
            }
          }
          .ranking-module:nth-child(3) {
            font-weight: bold;
            color: rgb(248, 51, 16);
            .rank-color:nth-child(1) {
              background-color: rgb(228, 160, 33);
            }
          }
        }
      }

      // 待审核事务
      .schedule {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 49%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

        // 标题
        .schedule-title {
          display: flex;
          align-items: center;
          justify-content: center;
          // border-left: 3px solid #40a3ff;
          background-color: @indexTitleBachgroundColor;
          width: 100%;
          height: 10%;
          box-sizing: border-box;
          padding-left: 10%;
          div {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            font-weight: bolder;
            font-size: 0.9vw;
            color: rgb(65, 64, 64);
          }
        }
        // 内容
        .schedule-content {
          width: 100%;
          height: 90%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          justify-content: space-between;
          padding: 4%;
          box-sizing: border-box;
          // 整个内容,与边框隔开距离
          .schedule-part {
            width: 48%;
            height: 46%;
            display: flex;
            flex-direction: column;
            background-color: #f2f9ff;
            border-radius: 5px;
            .schedule-part-top {
              width: 100%;
              height: 40%;
              display: flex;
              align-items: center;
              .schedule-part-top-img {
                width: 50px;
                height: 50px;
                border-radius: 5px;
                background-color: #40a3ff;
                margin-left: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 70%;
                  height: 70%;
                }
              }
              .schedule-part-top-title {
                flex: 1;
                height: 100%;
                display: flex;
                align-items: center;
                color: #40a3ff;
                font-size: 14px;
                margin-left: 3px;
              }
              .schedule-part-top-refresh {
                color: #40a3ff;
                margin-right: 5px;
                &:hover {
                  cursor: pointer;
                }
              }
            }
            .schedule-part-list {
              width: 100%;
              height: 60%;
              display: flex;
              flex-direction: column;
              align-items: center;
              .schedule-part-list-item {
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 5px;
                box-sizing: border-box;
                font-size: 12px;
                transition: 0.3s;
                &:hover {
                  cursor: pointer;
                  background-color: #40a3ff18;
                  color: #40a3ff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
